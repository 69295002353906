<template>
  <div class="body container" />
</template>

<script>
export default {
  computed: {
    shortUrl() {
      return this.$store.getters["shortUrls/shortUrl"];
    }
  },
  async created() {
    await this.$store.dispatch(
      "shortUrls/revertShortUrl",
      this.$route.params.pathMatch
    );
    window.location.href = this.shortUrl.full_url;
  }
};
</script>
